<template>
<!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
      <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 80%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=85%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 10vh">
              <div><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showUnsusbscribe -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td height=6vh>
                    <table width="100%" border=0 style="height: 7vh">
                        <tr>
                            <td width="25%">
                                <div class="divl" style="height: 6vh; display: flex;" ><button class="text-only-button cancel" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doShowHomeScreen();">Home</button></div>
                            </td>
                            <td width="50%" valign=center @click="this.doShowHomeScreen();">
                                <img class="tulogo" valign=center style="height: 7vh; padding-bottom: 1vh" alt="thankU logo" src="../assets/tutxtlogo.png">
                            </td>
                            <td width="25%" valign="center" align=right style="height: 6vh; ">
                                <table border=0 cellpadding=0 cellspacing=0 >
                                    <tr>
                                        <td align=right>
                                           &nbsp;
                                        </td>
                                    </tr>
                                     
                                </table>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
        </table>
        <br>
        <table width="100%" border=0 style="height: 6vh">
            <tr>
                <td width="1%">
                    <div class=divl>
                        &nbsp;
                    </div>
                </td>
                <td width="98%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse *1.2 + 'px', }"></span>
                </td>
                <td width="1%">
                <div class=divr>
                    &nbsp;
                    
                </div>
                </td>

            </tr>
        </table>
        </div>
    </div>

</template>

<script>

import shared from '../shared.js';

const Parse = require('parse/node');

export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    data(){
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeTitle: 2.7, // vh
            fontSizeTitleLineHeight: 5, //vh
            fontSizePopUpMsg: 2.3, // vh
            fontSizePopUpMsgLineHeight: 3.4,// vh
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
        }
    },
    methods: {
        doPopUpOk(){
            this.showPopUpOk = false;
            this.doShowHome();
        },
        doShowHome(){
        // /console.log("we're off!");

        let returnJSON = { showHome: true }; 
        this.$emit('return-json', returnJSON);

        },   
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
        }, 
        async unsubscribeEmail(){

            try {
                if (window.localStorage.getItem('emailToUnsubscribe') !== null){
                
                //TODO make sure that the emails are prepared with the %2B for '+'

                    let email = window.localStorage.getItem('emailToUnsubscribe');
                    window.localStorage.removeItem("emailToUnsubscribe");

                    var params = {
                        email: email,
                    };

                    
                    let result = await Parse.Cloud.run("unsubscribeEmail", params);

                    if (result.ok) {
                        this.popUpMsgTitle = "Done!";
                        this.popUpMsgBody = "You will no longer receive newsletters to " + email + " from thankU"
                    } else if (result.notFound) {
                        this.popUpMsgTitle = "Sorry";
                        this.popUpMsgBody = "thankU could not find your email address " + email + " in our database - please contact support@thanku.app and ask us to check your email address against our records";
                    } 
                    this.showPopUpOk = true;
                }      
            } catch (e) {
                // console.log("unsubscribeEmail ERROR: " + e.message);
                this.popUpMsgTitle = "Oops";
                this.popUpMsgBody = "thankU experienced an error trying to unsubscribe you - please contact support@thanku.app with the following error message: " + e.message;
                this.showPopUpOk = true;
            }


        },
    }, 
    async created(){
        document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        // console.log("Unsubscribe created");
    
        shared.saveToUserPath(this.devEnv, "Unsubscribe " + new Date().toISOString(), this.globalPushForwardInterval);
    }, 
    mounted(){
        this.doFontSizeComputed();
        
        this.vhMult = shared.setScreenHeightMultiplier();

        this.unsubscribeEmail();
          

    }
}
</script>

